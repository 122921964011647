<template>
  <div class="wrapper">
    <menu-ver type="superuser" name="Superuser" />
    <div class="container m-3">
      <div class="table-header">
        <button
          class="btn button buttonCreate"
          data-bs-toggle="modal"
          data-bs-target="#addModal"
          @click="getActions(0)"
        >
          <i class="fas fa-plus-circle"></i> {{ $t("actions.create") }}
        </button>
      </div>
      <table class="table" v-if="languages && languages.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ $t("maintenance.languages.key") }}</th>
            <th scope="col">{{ $t("maintenance.languages.name") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in languages" :key="item.idio_id">
            <td>{{ item.idio_id }}</td>
            <td>{{ item.idio_nombre }}</td>
            <td>
              <button
                class="btn button buttonDelete ms-2"
                @click="getActions(2, item)"
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
              >
                <i class="fas fa-trash"></i>
              </button>
              <button
                class="btn button buttonEdit"
                @click="getActions(1, item)"
                data-bs-toggle="modal"
                data-bs-target="#editModal"
              >
                <i class="fas fa-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>{{ $t("errors.3") }}</p>
    </div>
  </div>

  <!-- Modal add -->
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{
              $t("maintenance.languages.addTitle", { name: `${languageId}` })
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.languages.key`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="FormRol"
                v-model.trim="idio_id"
                :placeholder="$t(`maintenance.languages.key`) + '*'"
                maxlength="5"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.languages.name`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="FormName"
                v-model.trim="idio_nombre"
                :placeholder="$t(`maintenance.languages.name`) + '*'"
                maxlength="50"
              />
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ $t("errors.fieldOb") }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="addLanguage"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal delete -->
  <div
    class="modal fade"
    id="deleteModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{
              $t("maintenance.languages.deleteTitle", { name: `${languageId}` })
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{ $t("maintenance.languages.deleteMessage") }}
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            id="btnDelete"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            @click="deleteLanguage"
          >
            {{ $t("maintenance.actions.confirm") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal edit -->
  <div
    class="modal fade"
    id="editModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{
              $t("maintenance.languages.editTitle", { name: `${languageId}` })
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{$t(`maintenance.languages.key`) + ' *'}}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="exampleDropdownFormRol"
                v-model.trim="idio_id"
                :placeholder="$t(`maintenance.languages.key`) + '*'"
                maxlength="5"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{$t(`maintenance.languages.name`) + ' *'}}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="exampleDropdownFormName"
                v-model.trim="idio_nombre"
                :placeholder="$t(`maintenance.languages.name`) + '*'"
                maxlength="50"
              />
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ $t("errors.fieldOb") }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="editLanguage"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal response -->
  <div
    class="modal fade"
    id="responseModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div v-if="loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
              <div class="modal-body" v-if="result === '0'">
                {{ $t("errors.0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.1") }}
              </div>
              <div class="modal-body" v-else-if="result === '2'">
                {{ $t("errors.2") }}
              </div>
              <div class="modal-body" v-else-if="result === '3'">
                {{ $t("errors.3") }}
              </div>
              <div class="modal-body" v-else-if="action === 2">
                {{ $t("errors.6") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="!loading">
          <button
            v-if="result === '0' || action === 2"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("actions.close") }}
          </button>
          <button
            v-if="action === 0 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#addModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
          <button
            v-if="action === 1 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#editModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../../components/MenuVer";
import TableMain from "../../../components/TableMain";
import axios from "axios";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    MenuVer,
    TableMain,
  },
  data() {
    return {
      languages: [],
      language: {
        idio_id: "",
        idio_nombre: "",
        idio_num: "",
      },
      idio_nombre: "",
      idio_id: "",
      languageId: "",
      result: "",
      action: 0,
      id: "",
      formError: false,
      loading: false,
    };
  },
  created() {
    this.getLanguages();
    this.changeBreadCrumb(4);
    this.bigDrop();
  },
  computed: {
    ...mapState(["servidor"])
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop"]),
    async getLanguages() {
      try {
      } catch (error) {
        console.error(error);
      }
      const res = await axios.post(
        `${this.servidor}api/op.asp?tp=idioma&ac=listado`
      );
      if (res.data) {
        this.languages = res.data.data;
      }
    },
    async addLanguage() {
      this.result = "";
      if (this.validateForm()) {
        try {
          this.loading = true;
          const language = {
            idio_id: this.idio_id.toUpperCase(),
            idio_nombre: this.idio_nombre.toUpperCase(),
          };

          const res = await axios.post(
            `${this.servidor}api/op.asp?tp=idioma&ac=grabar`,
            language
          );
          this.result = res.data.RESULT;
          $("#addModal").modal("hide");
          $("#responseModal").modal("show");
        } catch (error) {
          console.error(error);
          this.result = "1";
          $("#addModal").modal("hide");
          $("#responseModal").modal("show");
        }
        this.loading = false;
      }
    },
    async editLanguage() {
      this.result = "0";
      if (this.validateForm()) {
        try {
          this.loading = true;
          const language = {
            idio_id: this.idio_id.toUpperCase(),
            idio_nombre: this.idio_nombre.toUpperCase(),
            idio_num: this.language.idio_num,
          };
          const res = await axios.post(
            `${this.servidor}api/op.asp?tp=idioma&ac=actualizar`,
            language
          );
          this.result = res.data.RESULT;
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
        } catch (error) {
          this.result = "1";
          console.error(error);
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
        }
        this.loading = false;
      }
    },
    async deleteLanguage() {
      try {
        const borrado = {
          idio_id: this.id,
        };
        this.loading = true;
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=idioma&ac=borrar`,
          borrado
        );
        this.result = res.data.RESULT;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    getId(event) {
      this.languageId = event[0];
    },
    validateForm() {
      if (this.idio_id === "") {
        this.formError = true;
        return false;
      }
      if (this.idio_nombre === "") {
        this.formError = true;
        return false;
      }
      return true;
    },
    cleanForms() {
      this.idio_id = "";
      this.idio_nombre = "";
      this.loading = false;
      this.formError = false;
      this.getLanguages();
    },
    getActions(act, item) {
      this.action = act;
      if (act === 0) {
        return;
      }
      if (act === 1 && item) {
        this.language = item;
        this.idio_id = item.idio_id;
        this.idio_nombre = item.idio_nombre;
        return;
      }
      if (item.idio_id) {
        this.id = item.idio_id;
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}
.btn {
  float: right;
}
</style>