<template>
  <table class="table">
    <thead>
      <tr>
        <th scope="col" v-for="(col, index) in columns" :key="index">
          {{ col }}
        </th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item) in items" :key="item.id">
        <td v-for="(field, index) in fields" :key="index">{{ item }}.{{ field }}</td>
        <td>
          <button
            class="btn btn-danger ms-2"
            @click="$emit('delete', [item.id, 'delete'])"
            data-bs-toggle="modal"
            data-bs-target="#deleteModal"
          >
            <i class="fas fa-trash"></i>
          </button>
          <button
            class="btn btn-warning"
            @click="$emit('edit', [item.id, 'edit'])"
            data-bs-toggle="modal"
            data-bs-target="#editModal"
          >
            <i class="fas fa-edit"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["columns", "items"],
  data() {
    return {
      nFields: 0,
      fields: []
    }
  },
  created() {
    console.log(this.items)
    this.nFields = this.items.length
    if (this.nFields > 0) {      
      for (let key2 in this.items[0]) {
        console.log("key2", key2);
        this.fields.push(key2)
      }
    }
    console.log(this.items[0], this.fields[0])
    console.log("num", this.nFields)
    console.log("field", this.fields)
    for (let i in this.fields) {
      console.log(this.fields[i])
    }
  },
};
</script>

<style>
.btn {
  float: right;
}
</style>