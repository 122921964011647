<template>
  <div
    ref="menuver"
    class="menu-fixed"
  >
    <nav
      id="sidebar"
      style="margin-top: -0.5rem !important;background:#dddddd;"
      :style="{ height: height }"
    >
      <ul
        id="lista"
        class="list-unstyled components"
        v-if="type === 'superuser'"
      >
        <li>
          <router-link to="/adminsu/ccompany" :style="styleObject"
            ><i class="fas fa-building" :data-title="$t(`menu.mcompany`)"></i
          ></router-link>
        </li>
        <li>
          <router-link to="/adminsu/superuser" :style="styleObject"
            ><i class="fas fa-user-plus" :data-title="$t(`menu.msuperuser`)"></i
          ></router-link>
        </li>
        <li>
          <router-link to="/adminsu/statistic" :style="styleObject"
            ><i class="fas fa-chart-bar" :data-title="$t(`menu.statistics`)"></i
          ></router-link>
        </li>
        <li>
          <router-link to="/adminsu/mcountry" :style="styleObject"
            ><i
              class="fas fa-globe-europe"
              :data-title="$t(`menu.countryMain`)"
            ></i
          ></router-link>
        </li>
        <li>
          <router-link to="/adminsu/mnature" :style="styleObject"
            ><i class="fas fa-toolbox" :data-title="$t(`menu.nature`)"></i
          ></router-link>
        </li>
        <li>
          <router-link to="/adminsu/mrelation" :style="styleObject"
            ><i class="fas fa-link" :data-title="$t(`menu.relation`)"></i
          ></router-link>
        </li>
        <li>
          <router-link to="/adminsu/mlanguage" :style="styleObject"
            ><i
              class="fas fa-language"
              :data-title="$t(`menu.mainLanguages`)"
            ></i
          ></router-link>
        </li>
        <li>
          <router-link to="/adminsu/mfont" :style="styleObject"
            ><i
              class="fas fa-laptop-house"
              :data-title="$t(`menu.fontMain`)"
            ></i
          ></router-link>
        </li>
      </ul>
      <ul id="lista" class="list-unstyled components" v-if="type === 'company'">
        <li>
          <router-link to="/admin/mylinks" :style="styleObject"
            ><i
              class="fas fa-external-link-alt"
              :data-title="$t(`menu.mylinks`)"
            ></i
          ></router-link>
        </li>
        <li>
          <router-link to="/admin/user" :style="styleObject"
            ><i class="fas fa-users" :data-title="$t(`menu.users`)"></i
          ></router-link>
        </li>
        <li>
          <router-link to="/admin/mdepartment" :style="styleObject"
            ><i class="fas fa-sitemap" :data-title="$t(`menu.departments`)"></i
          ></router-link>
        </li>
        <li>
          <router-link to="/admin/mheadquarter" :style="styleObject"
            ><i
              class="fas fa-search-location"
              :data-title="$t(`menu.headquarters`)"
            ></i
          ></router-link>
        </li>
        <li>
          <router-link to="/admin/statistic" :style="styleObject"
            ><i class="fas fa-chart-bar" :data-title="$t(`menu.statistics`)"></i
          ></router-link>
        </li>
        <li>
          <router-link to="/admin/configuration" :style="styleObject"
            ><i class="fas fa-cogs" :data-title="$t(`menu.config`)"></i
          ></router-link>
        </li>
      </ul>
      <ul id="lista" class="list-unstyled components" v-if="type === 'user'">
        <!-- <li>
          <router-link :to="ruteMyData">{{ $t("menu.mydata") }}</router-link>
        </li> -->
        <li>
          <router-link :to="ruteOCase" :style="styleObject"
            ><i class="fas fa-folder-open" :data-title="$t(`menu.ocases`)"></i
          ></router-link>
        </li>
        <li>
          <router-link :to="ruteCHistory" :style="styleObject"
            ><i class="fas fa-history" :data-title="$t(`menu.history`)"></i
          ></router-link>
        </li>
      </ul>
      <ul
        id="lista"
        class="list-unstyled components"
        v-if="type === 'whistleblower'"
      >
        <li>
          <router-link :to="ruteSearch" :style="styleObject"
            ><i class="fas fa-search" :data-title="$t(`menu.searcher`)"></i
          ></router-link>
        </li>
      </ul>
    </nav>
  </div>
  <div class="menu"></div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "App",
  props: ["type", "name", "id_emp"],
  data() {
    return {
      height: "100%",
      ruteMyData: "",
      ruteOCase: "",
      ruteCHistory: "",
      ruteNotice: "",
      ruteSearch: ""
    };
  },
  computed: {
    ...mapState(["bigmenu", "colorCompany","servidor"]),
    styleObject() {
      // console.log("MENUCOLOR", this.colorCompany);
      this.getColorType();
      return {
        "--button-color--hover": this.colorCompany,
      };
    },
  },
  mounted() {
    this.matchHeight();
    window.addEventListener("resize", this.matchHeight);
    this.changeCom();
  },
  unmounted() {
    window.removeEventListener("resize", this.matchHeight);
  },
  methods: {
    ...mapMutations([
      "changeColorCompanyGreen",
      "changeColorCompanyBlue",
      "changeColorCompanyRed",
      "changeColorCompanyOrange",
      "changeColorCompany4",
      "changeColorCompany5",
      "changeColorCompany6",
      "changeColorCompany7",
      "changeColorCompany8",
      "changeColorCompany9",
      "changeColorCompany10",
      "changeColorCompany11",
      "changeColorCompany12",
      "changeColorCompany13",
      "changeColorCompany14",
      "changeColorCompany15",
      "changeColorCompany16"
    ]),
    matchHeight() {
      const w = window.innerHeight - 205;
      const ul = document.getElementById("lista");

      if (ul.clientHeight >= w) {
        this.height = ul.clientHeight.toString() + "px";
      } else {
        if (w >= 400) {
          this.height = w.toString() + "px";
        }
      }
    },
    changeCom() {
      this.ruteMyData = `/${this.id_emp}/user/mydata`;
      this.ruteOCase = `/${this.id_emp}/user/ocases`;
      this.ruteCHistory = `/${this.id_emp}/user/chistory`;
      this.ruteNotice = `/${this.id_emp}/user/notice`;
      this.ruteSearch = `/${this.id_emp}/whistleblower/search`;
      // console.log(this.ruteSearch);
    },
    async getColorType() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?ac=color&id=${this.id_emp}`
        );
        // console.log(res);
        const color = res.data.emp_color;
        // console.log("COLOR", color);
        if (color === 0) {
          this.changeColorCompanyOrange();
        } else if (color === 1) {
          this.changeColorCompanyBlue();
        } else if (color === 2) {
          this.changeColorCompanyGreen();
        } else if (color === 3) {
          this.changeColorCompanyRed();
        } else if (color === 4) {
          this.changeColorCompany4();
        } else if (color === 5) {
          this.changeColorCompany5();
        }else if (color === 6) {
          this.changeColorCompany6();
        }else if (color === 7) {
          this.changeColorCompany7();
        }else if (color === 8) {
          this.changeColorCompany8();
        }else if (color === 9) {
          this.changeColorCompany9();
        }else if (color === 10) {
          this.changeColorCompany10();
        }else if (color === 11) {
          this.changeColorCompany11();
        }else if (color === 12) {
          this.changeColorCompany12();
        }else if (color === 13) {
          this.changeColorCompany13();
        }else if (color === 14) {
          this.changeColorCompany14();
        }else if (color === 15) {
          this.changeColorCompany15();
        }else if (color === 16) {
          this.changeColorCompany16();
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.header {
  border-right: 1px solid #cccccc;
  margin-bottom: 6pt;
  padding: 26pt;
}

p {
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  background-color: white;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;
}

#sidebar {
  min-width: 153pt;
  max-width: 196pt;
  min-height: 573px;
  background: #696768;
  transition: all 0.3s;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

#sidebar.active {
  margin-left: -250px;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #649be6;
}

#sidebar ul.components {
  padding: 25pt;
  font-size: 9pt;
  /* height: 100%; */
}

#sidebar ul p {
  padding: 10px;
  margin-left: 18px;
}

#sidebar ul li a {
  padding-top: calc(0.8em + 0.8vw);
  padding-bottom: 10pt;
  font-size: 1.1em;
  display: block;
}
#sidebar ul li a:hover {
  /* color: #666666; */
  color: var(--button-color--hover);
}

.router-link-active,
.router-link-exact-active a {
  color: var(--button-color--hover);
}

li {
  text-align: center;
}

ul {
  margin-bottom: 0pt;
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #696768;
}

i {
  font-size: calc(1.2em + 0.8vw);
}

.menu-fixed {
  background-color: #dddddd;
  margin-top: 86pt;
  position: fixed;
  height: 100%;
  z-index: 100;
  /* overflow-y: scroll; */
}

.menu {
  min-width: 153pt;
  max-width: 196pt;
}
</style>